<template>
	<div>
		<v-data-table :items="items" :headers="headers">
			<template v-slot:item.user="{ item }">
				<span v-if="item.user && users[item.user]">
					{{ users[item.user].displayName }}
				</span>
			</template>
			<template v-slot:item.lastLogin="{ item }">
				<span v-if="item.user && users[item.user]">
					{{
						users[item.user].lastLogin
							? users[item.user].lastLogin.toDate()
							: users[item.user].created.toDate() | moment("D.MM.YYYY")
					}}
				</span>
			</template>
		</v-data-table>
	</div>
</template>

<style lang="less"></style>

<script type="text/javascript">
import Vuex from "vuex";
export default {
	name: "CollaborationDashboard",
	props: {},
	data: () => {
		return {};
	},
	computed: {
		...Vuex.mapState({
			users: (state) => state.users.data,
			assessment: (state) => state.assessment.data,
			collaborations: (state) => state.collaborations.data,
			departments: (state) => state.departments.data,
		}),
		isLead() {
			return this.$store.getters["assessment/isLead"];
		},
		headers() {
			return [
				{ value: "section", text: this.$t("sections.nav.single") },
				{ value: "collaborationStatus", text: this.$t("collaboration.status") },
				{ value: "user", text: this.$t("users.nav.single") },
				{ value: "lastLogin", text: this.$t("collaboration.last_login") },
			];
		},
		items() {
			const self = this;
			var order = self.$store.getters["departments/canEditOrder"]
			var responseMap = self.$store.getters.responseMap;
			var items = order.map( o => {
				var response = responseMap[o];
				var item = {};
				item.section = self.departments[o].name;
				item.status = response.status;
				item.collaboration = response.collaboration;
				var collaboration = self.collaborations[response.collaboration];
				if( collaboration ){
					item.collaborationStatus = this.$t(`collaboration.statuses.${collaboration.status}`);
					item.user = collaboration.user;
				}
				return item;
			});
			return items;
		},
	},
	watch: {
		items: {
			immediate: true,
			handler() {
				const self = this;
				self.items.forEach((response) => {
					if( response.user ){
						self.$store.dispatch("users/fetchById", response.user);
					}
				});
			},
		},
	},
	// created(){

	// }
};
//
</script>
"
